import { useQuery } from '@vue/apollo-composable'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import { CheckUserIsValidDocument } from '@/graphqlTypes'

export const useCheckUserIsValid = () => {
  const route = useRoute()
  const isNotAuthorized = ref(false)

  const { loading, error, refetch, onResult, onError } = useQuery(
    CheckUserIsValidDocument,
    {},
    { errorPolicy: 'none' },
  )

  onResult(({ data }) => {
    isNotAuthorized.value = !data?.me.valid
  })
  onError(({ graphQLErrors }) => {
    isNotAuthorized.value = graphQLErrors[0] && graphQLErrors[0].errorType === 'Unauthorized'
  })

  const showMenu = computed(() => {
    return !isNotAuthorized.value && route.path.startsWith('/acc')
  })

  return { loading, error, refetch, isNotAuthorized, showMenu }
}
