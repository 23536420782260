import gsap from 'gsap'
import type { MaybeRef } from 'vue'
import { onUnmounted, reactive, unref, watch } from 'vue'

export const useNumberTweener = (number: MaybeRef<number>, options = { duration: 0.5 }) => {
  const tweened = reactive({ number: 0 })

  let tween: gsap.core.Tween | null = null

  watch(
    () => unref(number),
    (n) => {
      if (tween) {
        tween.kill()
      }
      tween = gsap.to(tweened, {
        duration: options.duration,
        number: Number(n),
      })
    },
    { immediate: true },
  )

  watch(tweened, (obj) => (obj.number = parseInt(obj.number.toFixed(0))))

  onUnmounted(() => {
    if (tween) {
      tween.kill()
    }
  })

  return { tweened }
}
