import { shallowRef } from 'vue'
import { useRouter } from 'vue-router'

import TheAppLayout from '../components/layouts/TheAppLayout.vue'
import TheAuthLayout from '../components/layouts/TheAuthLayout.vue'

export const AppLayout = 'AppLayout'
export const AuthLayout = 'AuthLayout'

const LAYOUTS = {
  [AppLayout]: TheAppLayout,
  [AuthLayout]: TheAuthLayout,
}

export const useLayout = () => {
  const router = useRouter()

  const layout = shallowRef('div')
  router.afterEach((to) => {
    layout.value = LAYOUTS[to.meta.layout]
  })

  return { layout }
}
