<template>
  <TheSkedrBanner v-if="showMenu" />
  <div class="min-h-screen bg-gray-100">
    <header class="pb-12 bg-gray-800">
      <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div class="py-5 flex items-center justify-center lg:justify-between">
          <!-- Logo -->
          <div class="flex-shrink-0 flex lg:static">
            <router-link :to="{ name: 'MyGroups' }" class="flex">
              <span class="sr-only">Skedr.io</span>
              <img class="h-16" src="/MainNegativeLogo.svg" alt="Skedr" />
            </router-link>
          </div>

          <!-- Right section on desktop -->
          <div class="hidden lg:ml-4 lg:flex lg:items-center lg:pr-0.5">
            <!-- Profile dropdown -->
            <div ref="target" class="ml-4 relative flex-shrink-0">
              <div class="flex space-x-4">
                <TheCredits v-if="showMenu" />
                <button
                  id="user-menu-button"
                  type="button"
                  class="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-white lg:p-1 lg:rounded-md lg:hover:bg-gray-700"
                  aria-expanded="false"
                  aria-haspopup="true"
                  @click="dropDownMenu = !dropDownMenu"
                >
                  <span class="flex w-full justify-between items-center">
                    <FlickrAccountPill />
                    <ChevronUpDownIcon
                      class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    />
                  </span>
                </button>
              </div>

              <BaseFadeTransition>
                <div
                  v-show="dropDownMenu"
                  class="origin-top-right z-40 absolute -right-2 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabindex="-1"
                >
                  <div class="py-1" role="none">
                    <BaseNavLink
                      :to="{ name: 'Account' }"
                      class="flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      active-class="bg-gray-100"
                      role="menuitem"
                    >
                      <FolderIcon class="flex-shrink-0 mr-1.5 h-5 w-5" />
                      Dashboard
                    </BaseNavLink>
                    <BaseNavLink
                      id="user-menu-item-0"
                      :to="{ name: 'Settings' }"
                      class="flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      active-class="bg-gray-100"
                      role="menuitem"
                    >
                      <CogIcon class="flex-shrink-0 mr-1.5 w-5 h-5" />
                      Settings
                    </BaseNavLink>
                  </div>
                  <div class="py-1" role="none">
                    <a
                      data-canny-link
                      href="https://skedr.canny.io"
                      target="_blank"
                      class="flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      role="menuitem"
                    >
                      <QuestionMarkCircleIcon class="flex-shrink-0 mr-1.5 w-5 h-5" />
                      Feature requests
                    </a>
                    <button
                      type="button"
                      class="flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                      role="menuitem"
                      @click="
                        () => {
                          dropDownMenu = !dropDownMenu
                          openBeacon()
                        }
                      "
                    >
                      <ChatBubbleBottomCenterIcon class="flex-shrink-0 mr-1.5 w-5 h-5" />
                      Support
                    </button>
                    <a
                      href="https://skedr.io/blog"
                      target="_blank"
                      class="flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      <AcademicCapIcon class="flex-shrink-0 mr-1.5 w-5 h-5" />
                      Blog
                    </a>
                  </div>
                  <div class="py-1" role="none">
                    <a
                      id="user-menu-item-2"
                      href="#"
                      class="flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      @click.prevent="signOutUser()"
                    >
                      <ArrowRightStartOnRectangleIcon class="flex-shrink-0 mr-1.5 w-5 h-5" />
                      Sign out
                    </a>
                  </div>
                </div>
              </BaseFadeTransition>
            </div>
          </div>

          <!-- Search -->
          <div class="flex-1 min-w-0 px-12 lg:hidden">
            <div class="max-w-xs w-full mx-auto">
              <BaseText
                id="search-mobile"
                ref="searchBar"
                v-model="searchTerm"
                dark
                type="search"
                label="Search groups"
                sr-only
                placeholder="Search groups"
                required
                @keyup.enter="onKeyUpSearch()"
              >
                <template #icon="{ size }">
                  <MagnifyingGlassIcon :class="size" />
                </template>
              </BaseText>
            </div>
          </div>

          <!-- Menu button -->
          <div class="absolute right-0 flex-shrink-0 lg:hidden">
            <!-- Mobile menu button -->
            <button
              type="button"
              class="bg-transparent p-2 rounded-md inline-flex items-center justify-center text-indigo-200 hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white"
              aria-expanded="false"
              @click="mobileMenu = true"
            >
              <span class="sr-only">Open main menu</span>

              <Bars3Icon class="block h-6 w-6" />
            </button>
          </div>
        </div>
        <div class="hidden lg:block pb-5">
          <div v-if="showMenu" class="grid grid-cols-3 gap-8 items-center">
            <div class="col-span-2">
              <nav id="app-menu" class="flex space-x-4">
                <BaseNavLink
                  :to="{ name: 'MyGroups' }"
                  class="flex text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10"
                  inactive-class="text-indigo-100"
                  active-class="text-white bg-opacity-10"
                >
                  <FolderIcon class="flex-shrink-0 mr-1.5 h-5 w-5" />
                  Groups
                </BaseNavLink>
                <BaseNavLink
                  :to="{ name: 'MyPhotostream' }"
                  class="flex text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10"
                  inactive-class="text-indigo-100"
                  active-class="text-white bg-opacity-10"
                >
                  <PhotoIcon class="flex-shrink-0 mr-1.5 w-5 h-5" />
                  Photostream
                </BaseNavLink>
                <BaseNavLink
                  :to="{ name: 'Scheduled' }"
                  class="flex text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10"
                  inactive-class="text-indigo-100"
                  active-class="text-white bg-opacity-10"
                >
                  <CalendarIcon class="flex-shrink-0 mr-1.5 w-5 h-5" />
                  Scheduled
                </BaseNavLink>
              </nav>
            </div>
            <div class="max-w-md w-full mx-auto">
              <BaseText
                id="search"
                ref="searchBar"
                v-model="searchTerm"
                dark
                type="search"
                label="Search groups"
                sr-only
                placeholder="Search groups"
                required
                @keyup.enter="onKeyUpSearch()"
              >
                <template #icon="{ size }">
                  <MagnifyingGlassIcon :class="size" />
                </template>
              </BaseText>
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile menu, show/hide based on mobile menu state. -->
      <div class="lg:hidden">
        <transition
          appear
          enter-active-class="ease-in-out duration-150"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="ease-in-out duration-150"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-show="mobileMenu"
            class="z-20 fixed inset-0 bg-black bg-opacity-25"
            aria-hidden="true"
          ></div>
        </transition>

        <transition
          appear
          enter-active-class="ease-in-out duration-150"
          enter-from-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="ease-in-out duration-150"
          leave-from-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
        >
          <div
            v-show="mobileMenu"
            class="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top"
          >
            <div
              class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200"
            >
              <div class="pt-3 pb-2">
                <div class="flex items-center justify-between px-4">
                  <div>
                    <span class="sr-only">Skedr</span>
                    <img class="h-16" src="/MainPositiveLogo.svg" alt="Skedr" />
                  </div>
                  <div class="-mr-2">
                    <button
                      type="button"
                      class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                      @click="mobileMenu = false"
                    >
                      <span class="sr-only">Close menu</span>
                      <!-- Heroicon name: outline/x -->
                      <XMarkIcon class="h-6 w-6" />
                    </button>
                  </div>
                </div>
                <div class="mt-3 px-2 space-y-1">
                  <BaseNavLink
                    :to="{ name: 'MyGroups' }"
                    class="flex rounded-md px-3 py-2 text-base font-medium hover:bg-gray-100 hover:text-gray-800"
                    inactive-class="text-gray-900"
                    active-class="bg-gray-100"
                  >
                    <FolderIcon class="flex-shrink-0 mr-1.5 h-5 w-5" />
                    Groups
                  </BaseNavLink>
                  <BaseNavLink
                    :to="{ name: 'MyPhotostream' }"
                    class="flex rounded-md px-3 py-2 text-base font-medium hover:bg-gray-100 hover:text-gray-800"
                    inactive-class="text-gray-900"
                    active-class="bg-gray-100"
                  >
                    <PhotoIcon class="flex-shrink-0 mr-1.5 w-5 h-5" />
                    Photostream
                  </BaseNavLink>
                  <BaseNavLink
                    :to="{ name: 'Scheduled' }"
                    class="flex rounded-md px-3 py-2 text-base font-medium hover:bg-gray-100 hover:text-gray-800"
                    inactive-class="text-gray-900"
                    active-class="bg-gray-100"
                  >
                    <CalendarIcon class="flex-shrink-0 mr-1.5 w-5 h-5" />
                    Scheduled
                  </BaseNavLink>
                </div>
              </div>
              <div class="pt-4 pb-2">
                <FlickrAccountPill mobile />

                <div class="mt-3 px-2 space-y-1" role="none">
                  <a
                    data-canny-link
                    href="https://skedr.canny.io"
                    target="_blank"
                    class="flex rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800 cursor-pointer"
                    role="menuitem"
                  >
                    <QuestionMarkCircleIcon class="flex-shrink-0 mr-1.5 w-5 h-5" />
                    Feature requests
                  </a>
                  <BaseNavLink
                    id="user-menu-item-0"
                    :to="{ name: 'Settings' }"
                    class="flex rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800 cursor-pointer"
                    active-class="bg-gray-100"
                    role="menuitem"
                  >
                    <CogIcon class="flex-shrink-0 mr-1.5 w-5 h-5" />
                    Settings
                  </BaseNavLink>
                  <a
                    href="https://skedr.io/blog"
                    target="_blank"
                    role="menuitem"
                    class="flex rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800 cursor-pointer"
                  >
                    <AcademicCapIcon class="flex-shrink-0 mr-1.5 w-5 h-5" />
                    Blog
                  </a>
                  <a
                    id="user-menu-item-2"
                    href="#"
                    class="flex rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800 cursor-pointer"
                    role="menuitem"
                    @click.prevent="signOut()"
                  >
                    <ArrowRightStartOnRectangleIcon class="flex-shrink-0 mr-1.5 w-5 h-5" />
                    Sign out
                  </a>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </header>
    <main class="-mt-12 pb-8">
      <div class="max-w-3xl mx-auto px-0 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 class="sr-only">{{ $route.name }}</h1>
        <router-view />
      </div>
    </main>
    <footer>
      <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div class="border-t border-gray-200 py-8 text-sm text-gray-500 text-center sm:text-left">
          <span class="block sm:inline">&copy; 2021 Skedr.io&nbsp;</span>
          <span class="block sm:inline">All rights reserved.</span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
  import {
    Bars3Icon,
    ChevronUpDownIcon,
    CogIcon,
    MagnifyingGlassIcon,
  } from '@heroicons/vue/20/solid'
  import {
    AcademicCapIcon,
    ArrowRightStartOnRectangleIcon,
    CalendarIcon,
    ChatBubbleBottomCenterIcon,
    FolderIcon,
    PhotoIcon,
    QuestionMarkCircleIcon,
    XMarkIcon,
  } from '@heroicons/vue/24/outline'
  import { useQuery } from '@vue/apollo-composable'
  import { onClickOutside } from '@vueuse/core'
  import { ref, useTemplateRef, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'

  import { useBeacon } from '@/composables/useBeacon'
  import { useCheckUserIsValid } from '@/composables/useCheckUser'
  import { useSearch } from '@/composables/useSearch'
  import { apolloClient } from '@/configs/Vue3Apollo'
  import { MeDocument } from '@/graphqlTypes'
  import { useUser } from '@/stores/user'

  const { openBeacon } = useBeacon()
  const { searchBar, searchTerm, onKeyUpSearch } = useSearch()

  const { showMenu } = useCheckUserIsValid()

  const mobileMenu = ref(false)
  const dropDownMenu = ref(false)

  function away() {
    dropDownMenu.value = false
    mobileMenu.value = false
  }

  const target = useTemplateRef('target')
  onClickOutside(target, away)

  const router = useRouter()
  const { signOut } = useUser()
  async function signOutUser() {
    await signOut()
    await apolloClient.clearStore()
    router.push({ name: 'Signin' })
  }

  const route = useRoute()
  watch(route, away)

  const { onResult } = useQuery(MeDocument)
  onResult(async (result) => {
    if (!result.loading) {
      const { userName, email, userId } = result.data.me
      await window.Canny('identify', {
        appID: '6568cc3d712af2fd071ec4c8',
        user: {
          name: userName,
          id: userId,
          email: email,
        },
      })
    }
  })
</script>
