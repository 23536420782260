import { DefaultApolloClient } from '@vue/apollo-composable'
import { createApp, h, provide } from 'vue'

import { useGtm } from '@/configs/GTM'
import { initSentry } from '@/configs/Sentry'
import { apolloClient } from '@/configs/Vue3Apollo'

import App from './App.vue'
import router from './router'
import store from './stores'

import './index.css'
import 'floating-vue/dist/style.css'

import '@/configs/Amplify'
import './utils/vitePreloadError'

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App),
})

initSentry(app)

app.use(store)
app.use(router)
app.mount('#app')

useGtm(app)
