<template>
  <div v-if="showBanner" class="inset-x-0" :class="{ 'fixed bottom-0': fixed }">
    <div :class="class600">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span v-if="$slots.icon" class="flex p-2 rounded-lg" :class="class800">
              <span class="text-white h-6 w-6">
                <slot name="icon" />
              </span>
            </span>
            <div class="ml-3 font-medium text-white">
              <slot />
            </div>
          </div>
          <div
            v-if="$slots.action"
            class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto space-x-4"
          >
            <slot name="action" />
          </div>
          <div v-if="dismissable" class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              class="-mr-1 flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              :class="hoverClass"
              @click="dismiss"
            >
              <span class="sr-only">Dismiss</span>
              <XMarkIcon class="h-6 w-6 text-white" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { XMarkIcon } from '@heroicons/vue/24/outline'
  import { computed, ref } from 'vue'

  const showBanner = ref(true)

  interface IProps {
    color?: 'indigo' | 'amber' | 'black'
    fixed?: boolean
    dismissable?: boolean
  }
  const { color = 'indigo', fixed = false, dismissable = false } = defineProps<IProps>()

  function dismiss() {
    showBanner.value = false
  }

  const class600 = computed(() => {
    switch (color) {
      case 'indigo':
      default:
        return 'bg-indigo-600'
      case 'amber':
        return 'bg-amber-600'
      case 'black':
        return 'bg-zinc-800'
    }
  })

  const class800 = computed(() => {
    switch (color) {
      case 'indigo':
      default:
        return 'bg-indigo-800'
      case 'amber':
        return 'bg-amber-800'
      case 'black':
        return 'bg-zinc-600'
    }
  })

  const hoverClass = computed(() => {
    switch (color) {
      case 'indigo':
      default:
        return 'hover:bg-indigo-500'
      case 'amber':
        return 'hover:bg-amber-500'
      case 'black':
        return 'hover:bg-zinc-500'
    }
  })
</script>
