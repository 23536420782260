<script lang="ts" setup>
  import { ExclamationCircleIcon } from '@heroicons/vue/24/outline'
  import { ref } from 'vue'
  const showBanner = ref(false)
</script>

<template>
  <BaseBanner
    v-if="showBanner"
    color="amber"
    ref="banner"
    dismissable
    class="text-sm leading-6 items-center"
  >
    <template #icon><ExclamationCircleIcon /></template>
    <strong class="font-semibold">Flickr has disabled the api key for Skedr.io</strong>
    <br />
    I'm trying to create a new api key and understand why has been disabled. Sorry for the
    inconvenience.
  </BaseBanner>
</template>
