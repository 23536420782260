<template>
  <router-link
    v-if="(!loading && isNotSubscribed) || show"
    :to="{ name: 'Credits' }"
    class="relative flex items-center space-x-1.5 py-2 px-4 rounded-full text-stone-300 bg-gray-700 text-sm"
  >
    <StarIcon class="h-5 w-5" />
    <BaseAnimateElement :value="credits" class="whitespace-nowrap" />

    <span
      v-if="credits.value < 100"
      class="absolute -right-2 -top-2 bg-gray-800 rounded-full overflow-hidden"
    >
      <ExclamationCircleIcon class="text-red-700 h-7 w-7" />
    </span>
  </router-link>
</template>

<script setup lang="ts">
  import { StarIcon } from '@heroicons/vue/24/outline'
  import { ExclamationCircleIcon } from '@heroicons/vue/24/solid'
  import { useLazyQuery, useSubscription } from '@vue/apollo-composable'
  import { computed, onMounted, watch } from 'vue'

  import { useCheckSubscription } from '@/composables/useCheckSubscription'
  import { MeDocument, OnUpdateUserCreditsDocument } from '@/graphqlTypes'
  import { useUser } from '@/stores/user'

  const { loading, isNotSubscribed } = useCheckSubscription()

  interface IProps {
    show?: boolean
  }
  defineProps<IProps>()

  const { result, load } = useLazyQuery(MeDocument)
  const credits = computed(() => {
    const value = result.value?.me.shares.credits ?? 0
    return { value, unit: ' credits' }
  })

  const store = useUser()

  onMounted(() => {
    if (store.state.tenantId) {
      load()
      subscribeToCreditsUpdate()
    }
  })

  watch(
    () => store.state.tenantId,
    (newTenantId) => {
      if (newTenantId) {
        load()
        subscribeToCreditsUpdate()
      }
    },
  )

  function subscribeToCreditsUpdate() {
    if (isNotSubscribed.value) {
      useSubscription(OnUpdateUserCreditsDocument, { userId: store.state.tenantId as string })
    }
  }
</script>
