<template>
  <span v-if="typeof value === 'object'">
    {{ tweened.number.toFixed(decimals) }}{{ value.unit ?? '' }}
  </span>
  <span v-else>{{ tweened.number.toFixed(decimals) }}</span>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue'

  import { useNumberTweener } from '@/composables/useNumberTweener'

  interface IProps {
    value: number | { value: number; unit: string }
    duration?: number
    decimals?: number
  }

  const { value, duration = 0.5, decimals = 0 } = defineProps<IProps>()

  const currentValue = ref(typeof value === 'object' ? value.value : value)
  const { tweened } = useNumberTweener(currentValue, { duration })

  watch(
    () => value,
    (newValue) => {
      const newNumericValue = typeof newValue === 'object' ? newValue.value : newValue
      currentValue.value = newNumericValue
    },
    { deep: true, immediate: true },
  )
</script>
