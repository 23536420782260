<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
  <TheNotificationBar />
</template>

<script setup lang="ts">
  import { useLayout } from './composables/useLayout'

  const { layout } = useLayout()
</script>
